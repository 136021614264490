<template>
  <div class="survey-list">
    <esmp-table-wrapper
      class="surveys"
      title="Опросы"
      :is-column-settings-button-showed="false"
      :is-allow-full-page="false"
    >
      <template #header-actions>
        <esmp-button size="small" @click="createSurvey">
          Добавить
        </esmp-button>
      </template>
      <esmp-table
        :columns="columns"
        :rows="rows"
        :loading="loading"
        stripe
      >
        <template #cell-isArchive="{ td, tr }">
          <esmp-switch
            :value="!td"
            shape="circular"
            @input="(val) => changePublic(tr, val)"
          />
        </template>
        <template #cell-actions="{ tr }">
          <div class="surveys__actions">
            <esmp-button
              class="surveys__actions-item"
              icon="doc-edit"
              view="function"
              @click="editSurvey(tr.id)"
            />
            <esmp-button
              class="surveys__actions-item"
              icon="trash"
              view="function"
              @click="showRemoveConfirmModal(tr.id)"
            />
          </div>
        </template>
      </esmp-table>
    </esmp-table-wrapper>
    <!--
    <esmp-pagination-adaptive
      class="surveys__pagination"
      :page-count="pageCount"
      :current-page.sync="currentPage"
    />
    -->
    <esmp-modal
      v-model="removeConfirmModalShowed"
      class="modal-confirm"
      @on-ok="deleteSurvey"
    >
      <template #header>
        Удаление опроса
      </template>
      После удаления данное действие нельзя будет отменить. Вы уверены?
    </esmp-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'SurveyList',
  props: {
    surveyType: {
      type: String,
      validator(value) {
        return ['survey'].includes(value);
      },
      default: 'survey',
    },
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          title: 'ID',
          key: 'id',
        },
        {
          title: 'Заголовок',
          key: 'name',
        },
        {
          title: '',
          key: 'actions',
        },
      ],
      rows: [],
      removeConfirmModalShowed: false,
      removedSurveyId: null,
      pageCount: 2,
      currentPage: 1,
    };
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    getSurveys() {
      this.loading = true;
      this.$API[this.surveyType].getSurveys({
        page: this.currentPage - 1,
      }).then(({ data }) => {
        this.rows = data;
        // this.pageCount = data.totalPages;
      }).finally(() => {
        this.loading = false;
      });
    },
    createSurvey() {
      this.$router.push({
        name: 'CreateSurvey',
        query: { type: this.surveyType },
      });
    },
    changePublic(tr, val) {
      console.log('changePublic', tr, val);
    },
    editSurvey(id) {
      this.$router.push({
        name: 'EditSurvey',
        params: { id },
        query: { type: this.surveyType },
      });
    },
    showRemoveConfirmModal(id) {
      this.removedSurveyId = id;
      this.removeConfirmModalShowed = true;
    },
    deleteSurvey() {
      this.setLoading({ key: 'page', value: true });
      this.$API[this.surveyType].deleteSurvey(this.removedSurveyId).then(() => {
        this.rows = this.rows.filter((f) => f.id !== this.removedSurveyId);
        this.$EsmpNotify.$show('Опрос удален', 'success');
      }).finally(() => {
        this.removedFormId = null;
        this.setLoading({ key: 'page', value: false });
      });
    },
  },
  mounted() {
    // Получение списка опросов
    this.getSurveys();
  },
};
</script>

<style lang="scss">
.surveys {
  &__pagination {
    margin-top: $base-gutter;
    justify-content: center;
  }
  &__actions {
    display: flex;
    flex-direction: row;
  }
  .esmp-table__thead-tr {
    .esmp-table__thead-th:first-child {
      width: 42px;
    }
    .esmp-table__thead-th:nth-child(3) {
      width: 48px;
    }
    .esmp-table__thead-th:last-child {
      width: 70px;
    }
  }
}
</style>
