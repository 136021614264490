<template>
  <div class="page">
    <survey-list survey-type="survey" />
  </div>
</template>

<script>
import SurveyList from './components/SurveyList.vue';

export default {
  name: 'Surveys',
  components: {
    SurveyList,
  },
};
</script>

<style lang="scss">
</style>
